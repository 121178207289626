<template>
  <div
    style="position: relative; height: calc(100vh - 50px); overflow-y: scroll"
  >
    <Breadcrumb :Breadcrumb="BreadcrumbCon" />

    <div class="search">
      <div>
        <el-input placeholder="请输入订单号" v-model="orderId"></el-input>
        <el-select
          v-model="city"
          placeholder="请选择城市"
          @change="handleSelectHotel"
        >
          <el-option
            v-for="item in optionsCity"
            :key="item.id"
            :label="item.country + '-' + item.city"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-select v-model="hotel" placeholder="请选择酒店">
          <el-option
            v-for="item in optionsHotel"
            :key="item.hotelId"
            :label="item.hotelName"
            :value="item.hotelId"
          >
          </el-option>
        </el-select>
        <el-date-picker
          v-model="date"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
        <el-button type="primary" @click="searchList">搜索</el-button>
        <el-button type="info" @click="reset">重置</el-button>
      </div>
    </div>

    <div class="table_con">
      <el-table :data="tableData" border>
        <el-table-column label="商品主图" align="center" width="200">
          <template slot-scope="scope">
            <el-image
              style="height: 120px"
              :src="scope.row.roomDTO.imageList[0]"
              :preview-src-list="scope.row.roomDTO.imageList"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column
          prop="roomDTO.goodsName"
          label="商品名称"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="bizOrderExt.nightNum"
          label="预定时长"
          width="260"
          align="center"
        >
          <template slot-scope="{ row }">
            <div style="display: flex; align-items: center">
              {{ row.bizOrderExt.checkInTime }} —
              <div class="time_in-out_night">
                {{ row.bizOrderExt.nightNum }} 晚
              </div>
              — {{ row.bizOrderExt.checkOutTime }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="roomDTO.price" label="单价" align="center">
        </el-table-column>
        <el-table-column
          prop="roomDTO.roomCapacity"
          label="人数"
          width="100"
          align="center"
        >
          <template slot-scope="{ row }">
            <p>成人{{ row.bizOrderExt.auditsNum }}</p>
            <p>儿童{{ row.bizOrderExt.childrenNum }}</p>
          </template>
        </el-table-column>
        <el-table-column
          prop="bizOrderExt.houseNum"
          label="房间数"
          width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="dailyStoke"
          label="实收款"
          align="center"
          width="160"
        >
          <template slot-scope="{ row }">
            <p v-if="row.payOrder.payType == 1">${{ row.payOrder.amount }}</p>
            <p v-else-if="row.payOrder.payType == 10">
              {{ row.payOrder.p95Amount }}<br />
              CPLE积分抵扣：-${{ row.payOrder.p05Amount }}
            </p>
          </template>
        </el-table-column>
        <el-table-column
          prop="payOrder.orderId"
          label="订单号"
          align="center"
          width="140"
        >
        </el-table-column>
        <el-table-column
          prop="bizOrder.createTime"
          label="下单时间"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="bizOrder.orderStatus"
          label="订单状态"
          width="170"
          align="center"
        >
          <template slot-scope="{ row }">
            <div>
              {{ row.bizOrder.orderStatus }}
            </div>
            <el-button size="mini" type="text" @click="handleOrderDetail(row)"
              >查看详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/BreadCrumb";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import { hideLoading, showLoading } from "@/util/loading";

import { format_time_date, format_time_date_noTime } from "@/util/funBusiness";

export default {
  components: {
    Breadcrumb,
  },
  inject: ["reload"],

  data() {
    return {
      BreadcrumbCon: [
        {
          name: "行世界",
        },
        {
          name: "酒店管理",
        },
        {
          name: "订单管理",
        },
      ], // 面包屑数据

      tableData: [],
      orderId: "",
      optionsCity: [],
      optionsHotel: [],
      city: "",
      hotel: "",
      date: "",
    };
  },

  mounted() {
    showLoading();
    const opt = {
      url: reqUrl.worldHotelGetOrderList,
      method: "POST",
      params: JSON.stringify({
        hotelId: "",
        roomId: "",
        roomDate: "",
      }),
      resFunc: (res) => {
        console.log(res.data);
        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        } else if (res.data.code == 0) {
          this.tableData = res.data.data.list;
          res.data.data.list.map((item) => {
            item.bizOrderExt.checkInTime = format_time_date_noTime(
              item.bizOrderExt.checkInTime
            );
            item.bizOrderExt.checkOutTime = format_time_date_noTime(
              item.bizOrderExt.checkOutTime
            );
            item.bizOrder.createTime = format_time_date(
              item.bizOrder.createTime
            );

            if (item.bizOrder.orderStatus == 1) {
              item.bizOrder.orderStatus = "未支付";
            } else if (item.bizOrder.orderStatus == 2) {
              item.bizOrder.orderStatus = "支付审核中";
            } else if (item.bizOrder.orderStatus == 3) {
              item.bizOrder.orderStatus = "支付审核通过，已支付";
            } else if (item.bizOrder.orderStatus == 4) {
              item.bizOrder.orderStatus = "支付审核拒绝";
            } else if (item.bizOrder.orderStatus == 5) {
              item.bizOrder.orderStatus = "用户取消";
            } else if (item.bizOrder.orderStatus == 6) {
              item.bizOrder.orderStatus = "平台取消";
            } else if (item.bizOrder.orderStatus == 7) {
              item.bizOrder.orderStatus = "已确认";
            }
          });
          hideLoading();
        }
        hideLoading();
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        hideLoading();
      },
    };
    request(opt);

    const optCity = {
      url: reqUrl.worldTravelSelectCity,
      method: "POST",
      params: JSON.stringify({
        city: "",
        country: "",
      }),
      resFunc: (res) => {
        console.log(res.data);
        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
        } else if (res.data.code == 0) {
          this.optionsCity = res.data.data.list;
        }
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
      },
    };
    request(optCity);

    const optHotel = {
      url: reqUrl.worldGetHotelListOption,
      method: "POST",
      params: JSON.stringify({
        cityId: "",
      }),
      resFunc: (res) => {
        console.log(res.data);
        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
        } else if (res.data.code == 0) {
          this.optionsHotel = res.data.data;
        }
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
      },
    };
    request(optHotel);
  },

  methods: {
    handleOrderDetail(row) {
      this.$router.push("/worldHotelOrderDetail");
      localStorage.setItem("hotelRowDetailData", JSON.stringify(row));
    },

    searchList() {
      showLoading();
      const opt = {
        url: reqUrl.worldHotelGetOrderList,
        method: "POST",
        params: JSON.stringify({
          orderId: this.orderId,
          startDate: this.date[0],
          endDate: this.date[1],
          cityId: this.city,
          hotelId: this.hotel,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          } else if (res.data.code == 0) {
            this.tableData = res.data.data.list;
            res.data.data.list.map((item) => {
              item.bizOrderExt.checkInTime = format_time_date_noTime(
                item.bizOrderExt.checkInTime
              );
              item.bizOrderExt.checkOutTime = format_time_date_noTime(
                item.bizOrderExt.checkOutTime
              );
              item.bizOrder.createTime = format_time_date(
                item.bizOrder.createTime
              );

              if (item.bizOrder.orderStatus == 1) {
                item.bizOrder.orderStatus = "未支付";
              } else if (item.bizOrder.orderStatus == 2) {
                item.bizOrder.orderStatus = "支付审核中";
              } else if (item.bizOrder.orderStatus == 3) {
                item.bizOrder.orderStatus = "支付审核通过，已支付";
              } else if (item.bizOrder.orderStatus == 4) {
                item.bizOrder.orderStatus = "支付审核拒绝";
              } else if (item.bizOrder.orderStatus == 5) {
                item.bizOrder.orderStatus = "用户取消";
              } else if (item.bizOrder.orderStatus == 6) {
                item.bizOrder.orderStatus = "平台取消";
              } else if (item.bizOrder.orderStatus == 7) {
                item.bizOrder.orderStatus = "已确认";
              }
            });
            hideLoading();
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
        },
      };
      request(opt);
    },

    handleSelectHotel() {
      const optHotel = {
        url: reqUrl.worldGetHotelListOption,
        method: "POST",
        params: JSON.stringify({
          cityId: this.city,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
          } else if (res.data.code == 0) {
            this.optionsHotel = res.data.data;
          }
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
        },
      };
      request(optHotel);
    },

    // 刷新
    refresh() {
      this.reload();
    },

    reset() {
      this.refresh();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../index.scss";
</style>
